import React, { useState, useEffect } from "react";
import BoxContainer from "components/BoxContainer";
import Text from "components/Text";
import Button from "../Button";
import EnquiryForm from "components/EnquiryForm";
import iconResolver from "utils/icon-resolver";
import amazonSvg from "assets/clients-white/amazon-white.svg";
import googleSvg from "assets/clients-white/google-white.svg";
import microsoftSvg from "assets/clients-white/microsoft-white.svg";
import wixSvg from "assets/clients-white/wix-white.svg";
import bloombergSvg from "assets/clients-white/bloomberg-white.svg";
import Servicesicon from "components/homepage/translationServices/servicesicon";

const HeroBanner = ({
  title,
  description,
  buttonDetails,
  seal,
  enquiry_form,
  footer_client_list,

}) => {

  const useExperiment = (experimentId) => {
    const [variant, setVariant] = useState();
    useEffect(() => {
      (async () => {
        // if (window.dataLayer) {
        //   await window.dataLayer.push({ event: "optimize.activate" });
        // }
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            // Set the variant to the state.
            setVariant(window.google_optimize.get(experimentId));
            clearInterval(intervalId);
          }
        }, 100);
      })();
    });
    return variant;
  };

  const variant = useExperiment("_b1eORemTP6uefA63zaTlQ");

  return (
    <section className="bg-gradient1  relative bg-desktop bg-cover   sm:px-0 pt-5 md:pt-16 pb-8 ">
      <div className="gradient px-2 sm:mt-0 mt-[100px]">
        {/* <div className="md-transparent md:bg-gradient-to-t from-[#00173A] to-transparent h-full "> */}
        <div className="max-w-7xl mx-auto flex flex-col">
          <div className="flex flex-col lg:flex-row gap-6 xl:gap-0 w-full px-3 sm:px-0  mx-auto justify-between items-start ">
            <div>
              <div className="hidden lg:block  pb-5">
                <ul className="  lg:flex  gap-8">
                  {!!seal.length &&
                    seal.map((item, i) => (
                      <li key={i} className="flex gap-1 flex-row items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                            fill="#4285F4"
                          />
                        </svg>
                        <Text className="text-base text-[#000C27] font-medium ml-2  lg:text-left text-center">
                          {item.text}
                        </Text>
                      </li>
                    ))}
                </ul>
              </div>
              <Text
                as="h1"
                className="font-bold text-[32px] lg:text-46 font-opensans xs:font-secondary text-midBlue mb-2 w-full break-normal md:w-full lg:w-[85%]"
              >
                {title}
              </Text>
              <Text
                className="font-normal text-17 sm:text-20 leading-8 font-opensans text-midBlue mb-12 w-full md:w-full lg:w-[85%]"
                as="div"
              >
                {description}
              </Text>
              <div className="flex pb-10 text-white mb-6">
                <Button 
                  // title={
                  //   variant == "1" ? "START TRANSLATION" : buttonDetails.text
                  // }
                  title={
                    variant == "1" ? "START TRANSLATION" : "TRANSLATE NOW"
                  }
                  href={buttonDetails.link}
                  className="uppercase rounded-full bg-orange hover:bg-newOrange-100 text-white cursor-pointer text-17 font-semibold py-4 px-8 w-[253px] text-center transition-colors duration-300"
                />
              </div>
            </div>
            <div className="w-full">
              {enquiry_form && (
                <EnquiryForm
                  title={enquiry_form?.title}
                  highlightWords={enquiry_form?.highlightWords}
                  highlightedColor={enquiry_form?.highlightedColor}
                  subtitle={enquiry_form?.subtitle}
                />
              )}
            </div>
            <div className="lg:hidden block w-full  pb-5">
                <ul className="  md:flex  gap-8 justify-between w-full">
                  {!!seal.length &&
                    seal.map((item, i) => (
                      <li key={i} className="flex gap-1 flex-row items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                            fill="#4285F4"
                          />
                        </svg>
                        <Text className="text-base text-white font-medium ml-2  lg:text-left text-center">
                          {item.text}
                        </Text>
                      </li>
                    ))}
                </ul>
              </div>
          </div>
          <div className="w-full pt-[80px] pb-[60px]">
            
            <Servicesicon  footer_logo={footer_client_list}/>
          
        
          </div>{" "}
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
